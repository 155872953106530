var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"form"},[_c('b-form',{staticClass:"mb-3",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{ref:"title",staticClass:"form-group"},[_c('label',{attrs:{"for":"titleInput"}},[_vm._v("Title")]),_c('ValidationProvider',{attrs:{"name":"titleInput","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"form-control",class:{'is-invalid': !!errors.length},attrs:{"clearable":false,"options":_vm.titleOptions,"label":"text","reduce":function (item) { return item.value; },"placeholder":"Select"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{ref:"firstName",staticClass:"form-group"},[_c('label',{attrs:{"for":"firstNameInput"}},[_vm._v("First name")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"firstName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.firstName),expression:"form.firstName"}],staticClass:"form-control",class:classes,attrs:{"type":"text","placeholder":"","name":"firstNameInput"},domProps:{"value":(_vm.form.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "firstName", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{ref:"lastName",staticClass:"form-group"},[_c('label',{attrs:{"for":"lastNameInput"}},[_vm._v("Last name")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"lastName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.lastName),expression:"form.lastName"}],staticClass:"form-control",class:classes,attrs:{"type":"text","placeholder":"","name":"lastNameInput"},domProps:{"value":(_vm.form.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "lastName", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{ref:"email",staticClass:"form-group"},[_c('label',{attrs:{"for":"emailInput"}},[_vm._v("Email address")]),_c('ValidationProvider',{attrs:{"rules":"required|email","name":"Email address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],ref:"emailInputRef",staticClass:"form-control",class:classes,attrs:{"type":"text","placeholder":"","autocomplete":"email","name":"emailInput"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{ref:"admin",staticClass:"form-group"},[_c('div',{staticClass:"custom-control custom-checkbox custom-control-large"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.admin),expression:"form.admin"}],staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"admin"},domProps:{"value":true,"checked":Array.isArray(_vm.form.admin)?_vm._i(_vm.form.admin,true)>-1:(_vm.form.admin)},on:{"change":function($event){var $$a=_vm.form.admin,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=true,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "admin", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "admin", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "admin", $$c)}}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"admin"}},[_c('h6',{staticClass:"mb-0"},[_vm._v("Organisation Admin "),_c('button',{staticClass:"btn form-info",attrs:{"type":"button","aria-label":"Show info"},on:{"click":function($event){return _vm.$showInfo('OrganisationAdmin')}}},[_vm._v("?")])])])])]),_c('b-alert',{attrs:{"variant":"danger","dismissible":"","show":_vm.showDismissibleAlert},on:{"dismissed":function($event){_vm.showDismissibleAlert=false}}},[_vm._v(" "+_vm._s(_vm.serverError)+" ")]),_c('button',{staticClass:"btn btn-warning btn-lg btn-block",attrs:{"type":"submit"}},[_vm._v("Send invite")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }