<template>
  <div>
    <div class="input-group mb-2" v-if="showSearch">
      <input v-model="searchTerm" type="text" class="form-control" placeholder="Search" aria-label="Search user">
      <div class="input-group-append">
        <button class="btn btn-link" type="button" v-on:click="searchReset()">Show all</button>
      </div>
    </div>

    <b-pagination v-if="itemsComp.length > perPage"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="list-users"
      size="sm"
    ></b-pagination>

    <ul class="list-unstyled" id="list-users" v-if="itemsComp.length > 0">
      <li v-for="item in itemsForList" v-bind:key="item.id" class="border-bottom d-flex justify-content-between py-2 btn-list">
          <div>
            <h6 class="mb-0">{{item.display}}</h6>
          </div>
          <div class="d-flex">
              <div class="custom-control custom-checkbox custom-control-large mb-0 mr-4" v-if="showControlsComp.superAdmin">
                  <input type="checkbox" class="custom-control-input" :id="'superAdminCheck'+item.id" :value="true" v-model="item.superAdmin" @change="memberSuperAdminChange(item)">
                  <label class="custom-control-label" :for="'superAdminCheck'+item.id">Super Admin</label>
              </div>
              <div class="custom-control custom-checkbox custom-control-large mb-0 mr-4" v-if="showControlsComp.admin">
                  <input type="checkbox" class="custom-control-input" :id="'adminCheck'+item.id" :value="true" v-model="item.organisationAdmin" @change="memberAdminChange(item)">
                  <label class="custom-control-label" :for="'adminCheck'+item.id">Administrator</label>
              </div>
              <div class="custom-control custom-control-large mb-0 mr-4">
                  <button class="btn btn-sm btn-info" @click="viewUserProfiles(item)" v-if="showControlsComp.viewProfiles">
                      View Profiles
                  </button>
              </div>
              <div>
                  <button class="btn btn-sm btn-danger" @click="memberDelete(item)" v-if="showControlsComp.delete">
                      <span class="icon icon-trash"></span>
                  </button>
              </div>
          </div>
      </li>
    </ul>
    <p v-if="itemsComp.length < 1">Nothing to display</p>
  </div>
</template>

<script>
export default {
  name: 'ListControlUsers',
  props: {
    items: {type: Array, required: true},
    perPage: {type: Number, required: false, default: 10},
    display: {type: String, required: true},
    showControls: {type: String, required: true},
    sortBy: {type: String, required: true},
    showSearch: {type: Boolean, required: false, default: true}
  },
  data () {
    return {
      currentPage: 1,
      searchTerm: ''
    }
  },
  computed: {
    displayComp() {
      return this.display.split(',')
    },
    showControlsComp() {
      var arr = this.showControls.split(',')
      return arr.reduce((a,b) => {
        return (a[b]=true, a)
      }, {})
    },
    itemsComp() {
      var itemsNew = this.items

      // Search against top level item properties
      if (this.searchTerm !== '') {
        var itemsFiltered = itemsNew.filter(o =>
          Object.keys(o).some(k => {
            return typeof o[k] === 'string' &&  o[k].toLowerCase().includes(this.searchTerm.toLowerCase())
          }));
        itemsNew = itemsFiltered
      }

      // Add display prop based on display array of string and sort alphabetically
      var mappedItems = itemsNew.map(item => {
        var display = this.displayComp.map((x) => (item[x])).join(' ')
        return ({
          ...item, 
          display: display
        })
      }).sort((a, b) => {
        return a[this.sortBy].localeCompare(b[this.sortBy]);
      })

      return mappedItems
    },
    rows() {
      return this.itemsComp.length
    },
    itemsForList() {
      return this.itemsComp.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage,
      );
    },
  },
  methods: {
    searchReset() {
      this.searchTerm = ''
    },
    memberAdminChange(member) {
      this.$emit('memberAdminChange', member)
      },
    memberSuperAdminChange(member) {
      this.$emit('memberSuperAdminChange', member)
      },
    memberDelete(member) {
      this.$emit('memberDelete', member)
      },
     viewUserProfiles(member) {
      this.$emit('viewUserProfiles', member)
    }
  },
  watch: {
    search () {
      this.searchTerm !== '' ? this.currentPage = 1 : null
    }
  }
}
</script>