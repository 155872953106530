<template>
<div>
  <ValidationObserver ref="form">
    <b-form @submit.prevent="submit" class="mb-3">

      <div class="form-group" ref="title">
        <label for="titleInput">Title</label>
        <ValidationProvider name="titleInput" rules='required' v-slot="{errors}">
          <v-select
            :clearable="false"
            :options="titleOptions"
            label="text"
            v-model="form.title"
            :reduce="item => item.value"
            placeholder="Select"
            class="form-control"
            :class="{'is-invalid': !!errors.length}"
          ></v-select>
          <div class="invalid-feedback">{{ errors[0] }}</div>
        </ValidationProvider>
      </div>

      <div class="form-group" ref="firstName">
        <label for="firstNameInput">First name</label>
        <ValidationProvider v-slot="{classes, errors}" rules="required" name="firstName">
          <input v-model="form.firstName" type="text" placeholder="" name="firstNameInput" class="form-control" v-bind:class="classes">
          <div class="invalid-feedback">{{ errors[0] }}</div>
        </ValidationProvider>
      </div>

      <div class="form-group" ref="lastName">
        <label for="lastNameInput">Last name</label>
        <ValidationProvider v-slot="{classes, errors}" rules="required" name="lastName">
          <input v-model="form.lastName" type="text" placeholder="" name="lastNameInput" class="form-control" v-bind:class="classes">
          <div class="invalid-feedback">{{ errors[0] }}</div>
        </ValidationProvider>
      </div>

      <div class="form-group" ref="email">
        <label for="emailInput">Email address</label>
        <ValidationProvider v-slot="{classes, errors}" rules="required|email" name="Email address">
          <input ref="emailInputRef" v-model="form.email" type="text" placeholder="" autocomplete="email" name="emailInput" class="form-control" v-bind:class="classes">
          <div class="invalid-feedback">{{ errors[0] }}</div>
        </ValidationProvider>
      </div>

      <div class="form-group" ref="admin">
        <div class="custom-control custom-checkbox custom-control-large">
          <input type="checkbox" class="custom-control-input" id="admin" :value="true" v-model="form.admin">
          <label class="custom-control-label" for="admin">
            <h6 class="mb-0">Organisation Admin <button v-on:click="$showInfo('OrganisationAdmin')" class="btn form-info" type="button" aria-label="Show info">?</button></h6>
          </label>
        </div>
      </div>

      <b-alert variant="danger"
        dismissible
        :show="showDismissibleAlert"
        @dismissed="showDismissibleAlert=false">
        {{serverError}}
      </b-alert>
      <button type="submit" class="btn btn-warning btn-lg btn-block">Send invite</button>
    </b-form>
  </ValidationObserver> 
</div>

</template>

<script>
export default {
  name: 'OrganisationMemberAdd',
  props: ['organisation'],
  data () {
    return {
      form: {
        title: '',
        firstName: '',
        lastName: '',
        email: '',
        admin: false
      },
      serverError: '',
      showDismissibleAlert: false,
      titleOptions: this.$uiValues.titleOptions,
    }
  },
  computed: {
  },
  async created() {
    this.$store.state.appSection = 'appUser'
    this.$store.state.loadingProgress = false
  },
  beforeDestroy() {
  },
  methods: {
    showError(txt) {
      this.serverError = txt
      this.showDismissibleAlert = true
    },
    submit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            var el = this.$refs.form.refs[errors[0]["key"]].$el
            this.$scrollTo(el, 500, {offset: -100, cancelable: false})
          }, 100);
        } else {
          this.showDismissibleAlert = false
          this.$store.state.loadingProgress = true
          this.$http({
            method: 'post', 
            url: this.$api.TherapistAdminInvite,
            params: {
              sendEmail: true,
              organisationId: this.organisation.id,
              ...this.form
            },
            headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
          })
            .then((response) => {
              console.log(response)
              this.alertMsgShow('Invite sent!', true)
              this.$emit('invite-sent', true)
            })
            .catch((error) => {
              console.error('Error', error)
              this.showError('Something went wrong. Please try again.')
            })
            .finally(() => {
              this.$store.state.loadingProgress = false
            })
        }
      });
    }
  }
}
</script>
