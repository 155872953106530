<template>
<div>

  <!-- Modal delete organisation and member -->
  <b-modal id="modal-delete" centered hide-footer title-class="h3">
    <template v-slot:modal-title>
      Are you sure?
    </template>
    <div class="d-block">
      <p>{{deleteItem.question}}</p>
      <p><strong>{{deleteItem.itemDisplay}}</strong></p>
      <p>This action is irreversible.</p>
      <label for="verificationCode">Type in "DELETE" to activate the delete button</label>
      <input :value="deleteBlocker.toUpperCase()" @input="deleteBlocker = $event.target.value.toUpperCase()" type="text" placeholder="" class="form-control">
    </div>
    <div class="d-block mt-3">
      <div class="d-flex justify-content-between">
        <button class="btn btn-block text-dark btn-lg btn-link" v-on:click="$bvModal.hide('modal-delete')">Cancel</button>
        <button class="btn btn-block btn-lg btn-danger ml-1" v-on:click="deleteEntry()" :disabled="deleteBlocker !== 'DELETE'">Yes, delete</button>
      </div>
    </div>
  </b-modal>

  <!-- Modal add member -->
  <b-modal id="modal-add-member" centered hide-footer title-class="h3">
    <template v-slot:modal-title>
      Invite new member
    </template>
    <ModalInviteMember :organisation="organisation" @invite-sent="inviteSent" />
  </b-modal>

  <div class="container-fluid wrapper-medium mt-3" v-if="organisation !== null">

    <div class="row mb-3">
      <div class="col-12 col-md-7 mb-3">
          <b-link class="btn btn-link btn-sm pl-0" to="/organisations-management"><span class="icon icon-left-open mr-2"></span>Organisations management</b-link>
          <h2 class="anim-load1 mt-2 mb-3">{{organisation.name}}</h2>
          <p>View and edit organisation details and manage organisation members.</p>
      </div>
      <div class="col-12 col-md-5">
        <div class="card mb-3">
          <div class="card-body">
            <ValidationObserver ref="form">
              <b-form @submit.prevent="updateOrganisation" class="mb-3">

                <div class="form-group d-flex align-items-center justify-content-between" ref="credits">
                  <label class="h5" for="creditsInput">Credits</label>
                  <ValidationProvider v-slot="{classes, errors}" rules="required|integer|max_value:999999" name="Number of credits">
                    <input v-model="form.credits" type="text" placeholder="" name="creditsInput" class="form-control" v-bind:class="classes">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="mb-3">
                  <h5 class="accordion-title" v-b-toggle.collapse-1>Organisation details <span class="icon-up-open"></span></h5>
                  <b-collapse id="collapse-1" class="mt-2 accordion-content">
                    <div class="form-group" ref="organisationName">
                      <label for="organisationNameInput">Organisation name</label>
                      <ValidationProvider v-slot="{classes, errors}" rules="required" name="organisationName">
                        <input v-model="form.name" type="text" placeholder="" name="organisationNameInput" class="form-control" v-bind:class="classes">
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="form-group" ref="addressLine1">
                      <label for="addressLine1Input">Address line 1</label>
                      <input v-model="form.addressLine1" type="text" placeholder="" name="addressLine1Input" class="form-control">
                    </div>

                    <div class="form-group" ref="addressLine2">
                      <label for="addressLine2Input">Address line 2</label>
                      <input v-model="form.addressLine2" type="text" placeholder="" name="addressLine2Input" class="form-control">
                    </div>

                    <div class="form-group" ref="addressLine3">
                      <label for="addressLine3Input">Address line 3</label>
                      <input v-model="form.addressLine3" type="text" placeholder="" name="addressLine3Input" class="form-control">
                    </div>


                    <div class="form-group" ref="contactPhone">
                      <label for="contactPhoneInput">Contact phone number</label>
                      <input v-model="form.phoneNo" type="tel" placeholder="" name="contactPhoneInput" class="form-control">
                    </div>
                  </b-collapse>
                </div>

                <b-alert variant="danger"
                  dismissible
                  :show="showDismissibleAlert"
                  @dismissed="showDismissibleAlert=false">
                  {{serverError}}
                </b-alert>
                <button type="submit" class="btn btn-warning btn-lg btn-block">Update</button>
              </b-form>
            </ValidationObserver> 
          </div> 
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <div class="card mb-3">
          <div class="card-body">
            <div class="d-md-flex justify-content-between mb-3">
              <h4>Active members</h4>
              <div>
                 <b-link class="btn btn-warning btn-sm" v-on:click="$bvModal.show('modal-add-member')">Invite new member</b-link>
              </div>
            </div>
            
            <ListControlUsers
              :items="organisationMembers" 
              :perPage="20" 
              display="title,firstName,lastName"
              sortBy="firstName"
              :showSearch="false"
              showControls="superAdmin,admin,delete"
              @memberAdminChange="memberAdminChange"
              @memberSuperAdminChange="memberSuperAdminChange"
              @memberDelete="itemDeleteRequest"
              @viewUserProfiles="viewUserProfiles"
            />
          </div> 
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <div class="card mb-3">
          <div class="card-body">
            <h4>Pending invites</h4>
            
            <ListControlUsers
              :items="organisationInvites" 
              :perPage="20" 
              display="title,firstName,lastName"
              sortBy="firstName"
              :showSearch="false"
              showControls="delete"
              @memberDelete="itemDeleteRequest"
            />
          </div> 
        </div>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <hr>
        <h5 class="mb-3">Danger zone</h5>
        <b-link class="btn btn-danger btn-sm" v-on:click="itemDeleteRequest({organisation: true})">Delete this organisation</b-link>
      </div>
    </div>

  </div>

  <Footer />
</div>

</template>

<script>
import ListControlUsers from '@/components/modules/ListControlUsers'
import ModalInviteMember from '@/components/modules/ModalInviteMember'
export default {
  name: 'OrganisationsEdit',
  components: {
    ListControlUsers, ModalInviteMember
  },
  props: ['id'],
  data () {
    return {
      deleteBlocker: '',
      deleteItem: {},
      form: {
        name: '',
        addressLine1: null,
        addressLine2: null,
        addressLine3: null,
        phoneNo: null,
        credits: 0
      },
      organisation: null,
      organisationMembers: [],
      organisationInvites: [],
      serverError: '',
      showDismissibleAlert: false,
      titleOptions: this.$uiValues.titleOptions,
    }
  },

  computed: {
    userDisplay() {
      return `${this.form.title} ${this.form.firstName} ${this.form.lastName}`
    }
  },

  async created() {
    this.$store.state.appSection = 'appUser'
    this.$store.state.loadingProgress = true
    await Promise.all([this.getOrganisation(), this.getOrganisationMembers(), this.getOrganisationInvites()]);
    this.$store.state.loadingProgress = false
    this.$root.$on('bv::modal::hidden', () => {
      this.deleteBlocker = ''
    })
  },

  methods: {
    getOrganisation() {
      return this.$http({
        method: 'get', 
        url: this.$api.OrganisationAllGet,
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
      })
        .then((response) => {
          console.log(response)
          this.form = response.data.find(organisation => organisation.id === this.id)
          this.organisation = this.form
          if (!this.form) {
            this.alertMsgShow("Organisation doesn't exist", false)
            this.$router.push('/dashboard')
          }
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow("Something went wrong", false);
        })
    },

    getOrganisationMembers() {
      return this.$http({
        method: 'get', 
        url: this.$api.TherapistAllAdminGet,
        params: {
          organisationId: this.id
        },
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
      })
        .then((response) => {
          console.log(response)
          this.organisationMembers = response.data
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow("Something went wrong", false);
        })
    },

    getOrganisationInvites() {
      return this.$http({
        method: 'get', 
        url: this.$api.TherapistInvitesAdminGet,
        params: {
          organisationId: this.id
        },
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
      })
        .then((response) => {
          console.log(response)
          this.organisationInvites = response.data
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow("Something went wrong", false);
        })
    },

    showError(txt) {
      this.serverError = txt
      this.showDismissibleAlert = true
    },

    updateOrganisation() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            var el = this.$refs.form.refs[errors[0]["key"]].$el
            this.$scrollTo(el, 500, {offset: -100, cancelable: false})
          }, 100);
        } else {
          console.log(this.form, this.token)
          this.showDismissibleAlert = false
          this.$store.state.loadingProgress = true
          this.$http({
            method: 'post', 
            url: this.$api.OrganisationUpdate,
            params: {
              ...this.form
            },
            headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
          })
            .then((response) => {
              console.log(response)
              this.alertMsgShow('Organisation updated', true)
            })
            .catch((error) => {
              console.error('Error', error)
              this.showError('Something went wrong. Please try again.')
            })
            .then(() => {
              this.$store.state.loadingProgress = false
            })
        }
      });
    },

    memberAdminChange(member) {
      console.log('memberAdminChange received', member)
      this.$store.state.loadingProgress = true
      this.$http({
        method: 'post', 
        url: this.$api.TherapistUpdate,
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt },
        params: {
            admin: member.organisationAdmin,
           ...member
        }
      })
        .then(async (response) => {
          console.log(response.data)
          this.alertMsgShow('Member role has been updated', true)
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow('Something went wrong', false)
        })
        .finally(() => {
          this.$store.state.loadingProgress = false
        })
      },

      memberSuperAdminChange(member) {
          console.log("Member Super Admin Change", member)
          this.$store.state.loadingProgress = true
          this.$http({
              method: 'post',
              url: this.$api.TherapistUpdate,
              headers: { 'Authorization': 'bearer ' + this.$store.state.jwt },
              params: {
                  superAdmin: member.superAdmin,
                  ...member
              }
          })
              .then(async (response) => {
                  console.log(response.data)
                  this.alertMsgShow('Member role has been updated', true)
              })
              .catch((error) => {
                  console.error('Error', error)
                  this.alertMsgShow('Something went wrong', false)
              })
              .finally(() => {
                  this.$store.state.loadingProgress = false
              })
      },

    itemDeleteRequest(item) {
      console.log('itemDeleteRequest received', item)
      if (item.organisation) {
        // Request to delete the organisation
        this.deleteItem = {
          type: 'org',
          id: this.organisation.id,
          itemDisplay: this.organisation.name,
          question: `Are you sure you want to delete this organisation, including all members and parents data?`,
          endpoint: 'OrganisationDelete',
          key: 'id',
          deleteSuccessMsg: 'Organisation has been deleted'
        }
      } else {
        // Request to delete user account or invite
        var itemDisplay = item.title + ' ' + item.firstName + ' ' + item.lastName
        if (item.id !== null && item.id !== undefined) {
          // Request to delete user account
          this.deleteItem = {
            type: 'user',
            id: item.id,
            question: `Are you sure you want to delete this member?`,
            itemDisplay,
            endpoint: 'TherapistDelete',
            key: 'therapistId',
            deleteSuccessMsg: 'Account has been deleted'
          }
        } else {
          // Request to delete invite
          this.deleteItem = {
            type: 'invite',
            id: item.email,
            question: `Are you sure you want to delete this invite?`,
            itemDisplay,
            endpoint: 'TherapistInvitesAdminRevoke',
            key: 'email',
            deleteSuccessMsg: 'Invite has been revoked'
          }
        }
      }
      this.$bvModal.show('modal-delete')
    },


    deleteEntry() {
      // Deletes organisation, account or invite.
      this.$bvModal.hide('modal-delete')
      this.$store.state.loadingProgress = true
      this.$http({
        method: 'post', 
        url: this.$api[this.deleteItem.endpoint],
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt },
        params: {
          [this.deleteItem.key]: this.deleteItem.id
        }
      })
        .then(async (response) => {
          console.log(response.data)
          this.alertMsgShow(this.deleteItem.deleteSuccessMsg, true)
          if (this.deleteItem.type === 'org') {
            this.$router.push('/organisations-management')
          } else {
            this.loadData()
          }
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow('Something went wrong', false)
        })
        .then(() => {
          this.$store.state.loadingProgress = false
        })
    },

    inviteSent() {
      this.$bvModal.hide('modal-add-member')
      this.loadData()
      },

      /*viewUserProfiles(user) {
          this.$router.push({
              name: 'AdminProfileView',
              params: {
                  userId: user.id,
                  organisationId: this.organisation.id,
              }
          });
      },*/

    async loadData() {
      await Promise.all([this.getOrganisation(), this.getOrganisationMembers(), this.getOrganisationInvites()])
    }
    
  }
}
</script>
